// import { width } from '@mui/system';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";
import BottonMenu from "../uiComponents/Bottom";

const w3_close = () => {
  const width = document.getElementById("mySidebar").offsetWidth;
  document.getElementById("mySidebar").style.left = `-${width}px`;
  document.getElementById("sidebarOverlay").style.display = "none";
};
const w3_open = () => {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById("sidebarOverlay").style.display = "block";
};

const Header = ({ user, loggedIn }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const history = useHistory();

  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [isdropdownVisible, setIsdropdownVisible] = useState(false)

  const toggleDiv = () => {
    setIsdropdownVisible(!isdropdownVisible)
  }


  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response?.json();
    return setWebsiteSettings(data);
  };
  document.title = WebSitesettings
    ? WebSitesettings.WebTitle
    : "Skill based game";
  useEffect(() => {
    fetchData();
  }, []);

  const logout = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        window.location.reload();
        history.push("/");
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  return (
    <div>
      {/* {access_token ? (
        <React.Fragment>
          <div id="sidebarOverlay" onClick={w3_close}></div>
          <div
            className="w3-sidebar w3-bar-block"
            id="mySidebar"
            style={{ paddingBottom: "70px" }}
          >
            <Link
              to={"/Profile"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                {user && user.avatar ? (
                  <img
                    width="30px"
                    height="30px"
                    src={baseUrl + `${user && user.avatar}`}
                    alt="profile"
                    style={{ borderRadius: "50px" }}
                  />
                ) : (
                  <img
                    src={baseUrl + WebSitesettings.SmallLogo}
                    width="25px"
                    height="25px"
                    alt="profile"
                  />
                )}
              </picture>
              <div style={{ marginLeft: ".5rem" }}>My Profile</div>
            </Link>
            <Link
              to={"/landing"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={process.env.PUBLIC_URL + "/Images/Header/gamepad.jpg"}
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Win cash</div>
            </Link>
            <Link
              to={"/wallet"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={process.env.PUBLIC_URL + "/Images/Header/wallet.png"}
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>My wallet</div>
            </Link>

            <Link
              to={"/Gamehistory"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/gamesHistory.jpg"
                  }
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Game History</div>
            </Link>

            <Link
              to="/transaction-history"
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/order-history.png"
                  }
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Transaction History</div>
            </Link>

            <Link
              to={"/refer"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={process.env.PUBLIC_URL + "/Images/Header/sreferEarn.png"}
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Refer and Earn</div>
            </Link>

            <Link
              to={"/Referral-history"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/refer-history.webp"
                  }
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Refer History</div>
            </Link>

            <Link
              to={"/Notification"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/notifications.png"
                  }
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Notification</div>
            </Link>

            <Link
              to={"/support"}
              className="w3-bar-item w3-button"
              onClick={w3_close}
            >
              <picture className="icon">
                <img
                  alt="img"
                  src={process.env.PUBLIC_URL + "/Images/Header/support.png"}
                />
              </picture>
              <div style={{ marginLeft: ".5rem" }}>Support</div>
            </Link>

          </div>

          <div className="w3-teal">
            <div className="w3-container ">
              <div className={`${css.headerContainer} `}>
                <button
                  className="w3-button w3-teal w3-xlarge float-left"
                  onClick={w3_open}
                  id="hambergar"
                >
                  <picture className={`${css.sideNavIcon} mr-0`}>
                    <img
                      src="/Images/LandingPage_img/sidebar.png"
                      className="snip-img"
                      alt=""
                    />
                  </picture>
                </button>
                <Link to="/">
                  <picture className={`ml-2 ${css.navLogo} d-flex`}>
                    <img
                      src="/Images/LandingPage_img/Header_profile.png"
                      className="snip-img"
                      alt="Logo"
                    />
                  </picture>
                </Link>
                <div>
                  <div className={`${css.menu_items}`}>
                    <Link className={`${css.box}`} to="/Addcase">
                      <picture className={`${css.moneyIcon_container}`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/Images/LandingPage_img/global-rupeeIcon.png"
                          }
                          className="snip-img"
                          alt=""
                        />
                      </picture>
                      <div className="mt-1 ml-1">
                        <div className={`${css.moneyBox_header}`}>Cash</div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.Wallet_balance}
                        </div>
                      </div>
                      <picture className={`${css.moneyBox_add}`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/Images/LandingPage_img/addSign.png"
                          }
                          className="snip-img"
                          alt=""
                        />
                      </picture>
                    </Link>
                    <Link
                      className={`${css.box} ml-2`}
                      to="/redeem/refer"
                      style={{ width: "80px" }}
                    >
                      <picture className={`${css.moneyIcon_container}`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/Images/LandingPage_img/notification-activity-reward.png"
                          }
                          className="snip-img"
                          alt=""
                        />
                      </picture>
                      <div className="mt-1 ml-1">
                        <div className={`${css.moneyBox_header}`}>Earning</div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.referral_wallet}
                        </div>
                      </div>
                    </Link>
                  </div>
                  <span className="mx-5"></span>
                </div>
                <span className="mx-5"></span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="w3-teal ">
          <div className="w3-container ">
            <div className={`${css.headerContainer} justify-content-between`}>
              <Link to="/">
                <picture className={`ml-2 ${css.navLogo} d-flex`}>
                  <img
                    src="/Images/LandingPage_img/Header_profile.png"
                    className="snip-img"
                    alt="Logo"
                  />
                </picture>
              </Link>

              <div className={`ml-5`}>
                  <Link type='button' className='login-btn' to='/login'>
                    LOGIN
                  </Link>
                </div>
            </div>
          </div>
        </div>
      )} */}

<div>
        <div className='header_top_message'>
          <span>Commission: 5% ◉ Referral: 2% For All Games</span>
        </div>
        {access_token ? (
          <React.Fragment>
            <div id='sidebarOverlay' onClick={w3_close}></div>
            <div
              className='w3-sidebar w3-bar-block'
              id='mySidebar'
              style={{ paddingBottom: '70px' }}
            >
              <div className='sidebar_header'>
                <h2>Sarkari Ludo</h2>
              </div>

              <Link
                to={'/Profile'}
                className='w3-bar-item w3-button'
                onClick={w3_close}
              >
                <picture className='icon'>
                  {user && user.avatar ? (
                    <img
                      width='30px'
                      height='30px'
                      src="/user.png"
                      alt='profile'
                      style={{ borderRadius: '50px' }}
                    />
                  ) : (
                    <img src='../Images/avatars/Avatar7.png' alt='profile' />
                  )}
                </picture>
                <div style={{ marginLeft: '.5rem' }}>My Profile</div>
              </Link>

              <Link
                to={'/landing'}
                className='w3-bar-item w3-button'
                onClick={w3_close}
              >
                <picture className='icon'>
                  <img
                    alt='img'
                    src={process.env.PUBLIC_URL + '/Images/Header/gamepad.png'}
                  />
                </picture>
                <div style={{ marginLeft: '.5rem' }}>Win cash</div>
              </Link>


              <Link
                to={'/wallet'}
                className='w3-bar-item w3-button'
                onClick={w3_close}
              >
                <picture className='icon'>
                  <img
                    alt='img'
                    src={process.env.PUBLIC_URL + '/Images/Header/wallet.png'}
                  />
                </picture>
                <div style={{ marginLeft: '.5rem' }}>My wallet</div>
              </Link>
              <Link
                to={'/Gamehistory'}
                className='w3-bar-item w3-button'
                onClick={w3_close}
              >
                <picture className='icon'>
                  <img
                    alt='img'
                    src={
                      process.env.PUBLIC_URL + '/Images/Header/gamesHistory.png'
                    }
                  />
                </picture>
                <div style={{ marginLeft: '.5rem' }}>Game History</div>
              </Link>
              <Link
                to='/transaction-history'
                className='w3-bar-item w3-button'
                onClick={w3_close}
              >
                <picture className='icon'>
                  <img
                    alt='img'
                    src={
                      process.env.PUBLIC_URL + '/Images/Header/order-history.png'
                    }
                  />
                </picture>
                <div style={{ marginLeft: '.5rem' }}>Transaction History</div>
              </Link>
              <Link
                to={'/refer'}
                className='w3-bar-item w3-button'
                onClick={w3_close}
              >
                <picture className='icon'>
                  <img
                    alt='img'
                    src={process.env.PUBLIC_URL + '/Images/Header/sreferEarn.png'}
                  />
                </picture>
                <div style={{ marginLeft: '.5rem' }}>Refer and Earn</div>
              </Link>
              <Link
                to={'/Referral-history'}
                className='w3-bar-item w3-button'
                onClick={w3_close}
              >
                <picture className='icon'>
                  <img
                    alt='img'
                    src={
                      process.env.PUBLIC_URL + '/Images/Header/refer-history.webp'
                    }
                  />
                </picture>
                <div style={{ marginLeft: '.5rem' }}>Refer History</div>
              </Link>
              <Link
                to={'/Notification'}
                className='w3-bar-item w3-button'
                onClick={w3_close}
              >
                <picture className='icon'>
                  <img
                    alt='img'
                    src={
                      process.env.PUBLIC_URL + '/Images/Header/notifications.png'
                    }
                  />
                </picture>
                <div style={{ marginLeft: '.5rem' }}>Notification</div>
              </Link>
              <Link
                to={'/support'}
                className='w3-bar-item w3-button'
                onClick={w3_close}
              >
                <picture className='icon'>
                  <img
                    alt='img'
                    src={process.env.PUBLIC_URL + '/Images/Header/support.png'}
                  />
                </picture>
                <div style={{ marginLeft: '.5rem' }}>Support</div>
              </Link>
              <div
                className='w3-bar-item w3-button sidebar_link'
                onClick={toggleDiv}
              >
                <picture className='icon'>
                  <img
                    alt='img'
                    src={process.env.PUBLIC_URL + '/Images/Header/support.png'}
                  />
                </picture>
                
                <h3>{isdropdownVisible ? 'All Policy' : 'All Policy'}</h3>
              </div>
              {isdropdownVisible && (
                <ul className='dropdown_link'>
                <div style={{ marginLeft: '.5rem' }}>
                  <li>
                    <Link className='w3-bar-item w3-button' to='/privacy-policy' onClick={w3_close}>
                      Privacy Policy
                    </Link>
                  </li>
                  </div>
                  <li>
                    <Link className='w3-bar-item w3-button' to='/shipping-delivery-policy' onClick={w3_close}>
                      Shipping and Delivery Policy
                    </Link>
                  </li>
                  <li>
                    <Link className='w3-bar-item w3-button' to='/RefundPolicy' onClick={w3_close}>
                      Refund/Cancellation Policy
                    </Link>
                  </li>
                  <li>
                    <Link className='w3-bar-item w3-button' to='/term-condition' onClick={w3_close}>
                      Terms &amp; Condition
                    </Link>
                  </li>

                  <li>
                    <Link className='w3-bar-item w3-button' to='/contact-us' onClick={w3_close}>
                      Contact
                    </Link>
                  </li>
                </ul>
              )}
            </div>


            <div className='w3-teal'>
              <div className='w3-container '>
                <div className={`${css.headerContainer} `}>
                  <button
                    className='w3-button w3-teal w3-xlarge float-left'
                    onClick={w3_open}
                    id='hambergar'
                  >
                    <picture className={`${css.sideNavIcon} mr-0`}>
                      <img
                        src={process.env.PUBLIC_URL + '/Images/LandingPage_img/sidebar.png'}
                        className='snip-img'
                        alt=''
                      />
                    </picture>
                  </button>
                  <Link to='/'>
                    <img
                      src='/Images/LandingPage_img/Header_profile.png'
                      className='snip-img ml-2'
                      alt='logos'
                      width='50px'
                    />
                  </Link>

                  <div className={`${css.menu_items}`}>
                    <Link className={`${css.box}`} to='/Addcase'>
                      <picture className={`${css.moneyIcon_container}`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/Images/global-rupeeIcon.png'
                          }
                          className='snip-img'
                          alt=''
                        />
                      </picture>
                      <div className='mt-1 ml-1'>
                        <div className={`${css.moneyBox_header}`}>Cash</div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.Wallet_balance}
                        </div>
                      </div>
                      <picture className={`${css.moneyBox_add}`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/Images/LandingPage_img/addSign.png'
                          }
                          className='snip-img'
                          alt=''
                        />
                    {/* <IoIosAdd /> */}
                    {/* <IoIosAddCircleOutline /> */}
                    
                      </picture>
                    </Link>
                    <Link
                      className={`${css.box} ml-2`}
                      to='/redeem/refer'
                      style={{ width: '80px' }}
                    >
                      <picture className={`${css.moneyIcon_container}`}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/Images/LandingPage_img/notification-activity-reward.png'
                          }
                          className='snip-img'
                          alt=''
                        />
                      </picture>
                      <div className='mt-1 ml-1'>
                        <div className={`${css.moneyBox_header}`}>Earning</div>
                        <div className={`${css.moneyBox_text}`}>
                          {user && user.referral_wallet}
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className='w3-teal '>
            <div className='w3-container '>
              <div className={`${css.headerContainer} justify-content-between`}>
                <Link to='/'>
                  <picture className={`ml-2 ${css.navLogo} d-flex`}>
                    <img

                      src='/Images/LandingPage_img/Header_profile.png'
                      className='snip-img'
                      alt='SarkariLudo'
                    />
                  </picture>
                </Link>

                <div className={`ml-5`}>
                  <Link type='button' className='login-btn' to='/login'>
                    LOGIN
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}


      </div>

      <div className="bottom_menus_main">
        <BottonMenu />
      </div>
    </div>
  );
};

export default Header;
